import React, { useState, useEffect } from "react";
import "./Enquiry.css";
import axios from "axios";
import Swal from "sweetalert2";
import Helmet from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function Enquiry() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [error, setError] = useState("");
  const [userEnquiryDetails, setUserEnquiryDetails] = useState({
    name: "",
    email: "",
    contact_number: "",
    company_name: "",
    country_name: "",
    city_name: "",
    user_type: "CC",
  });

  const handleUserRegisterEnquiryDetails = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    // Check if the field is the userType radio input
    if (name === "user_type") {
      // Update the value to be "Corporate" or "Business" directly
      value = value === "CC" ? "CC" : "BB";
    }

    setUserEnquiryDetails((values) => ({ ...values, [name]: value }));
  };

  const handleUserEnquiry = async (event) => {
    event.preventDefault();
    axios
      .post(
        "https://backend.carsinafrica.com/frontend/enquiries",
        userEnquiryDetails
      )
      .then((response) => {
        // Handle the response from the API
        if (response.data.status === "Success") {
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            window.location.href = `/`;
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          });
        }
      })
      .catch((error) => {
        // Handle errors that occurred during the request
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    // Make an API request when the component mounts
    axios
      .get(
        `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=registration-inquiry&country=worldwide&country_iso=WW&url=carsinafrica.com`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setMetaData(response.data.data[0]);
          setMetaDataLoading(false);
        } else {
          console.error("Error Meta data");
        }
      })
      .catch((error) => {
        console.error("Error Meta data from API:", error);
      });
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <merg className="registrar mb-5 pt-5">
        <div className="container">
          <div className="row enquiry-section">
            <div className="col-lg-6">
              <form onSubmit={handleUserEnquiry}>
                <h3 className="display-5 mb-4">Enquiry Here</h3>
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    Name<span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control enquiy-form py-2"
                      id="formGroupExampleInput"
                      placeholder="Enter your name"
                      name="name"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                    {/* {feildError && <span style={{ color: "red" }}>{feildError}</span>} */}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Email <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      className={`form-control enquiy-form py-2`}
                      id="formGroupExampleInput"
                      placeholder="Enter your email address"
                      name="email"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Phone Number <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-mobile"></i>
                    </span>
                    <input
                      type="number"
                      className={`form-control enquiy-form py-2`}
                      id="formGroupExampleInput"
                      placeholder="Enter your phone number"
                      name="contact_number"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    Company Name <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-vcard"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control enquiy-form py-2"
                      id="formGroupExampleInput"
                      placeholder="Enter your company"
                      name="company_name"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    Country Name <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-globe"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control enquiy-form py-2"
                      id="formGroupExampleInput"
                      placeholder="Enter your country name"
                      name="country_name"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    City Name <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-street-view"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control enquiy-form py-2"
                      id="formGroupExampleInput"
                      placeholder="Enter your city name"
                      name="city_name"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3 enquiry-width">
                  <div className="form-check form-check-inline custom-radio circle-radio enquiry-custom corporate-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="user_type"
                      id="inlineRadio1"
                      value="CC"
                      onChange={handleUserRegisterEnquiryDetails}
                      defaultChecked={true}
                      required
                    />
                    <label
                      className="form-check-label corporate-width circle-radio"
                      htmlFor="inlineRadio1"
                    >
                      Corporate
                    </label>
                  </div>
                  <div className="form-check form-check-inline custom-radio circle-radio enquiry-custom business-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="user_type"
                      id="inlineRadio2"
                      value="BB"
                      onChange={handleUserRegisterEnquiryDetails}
                      required
                    />
                    <label
                      className="form-check-label business-width circle-radio"
                      htmlFor="inlineRadio2"
                    >
                      Business
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-success mb-3">
                  Submit
                </button>
              </form>
            </div>

            <div className="col-lg-6">
              <img
                src="./images/enquiry-here.jpg"
                className="img-responsive w-100 h-80"
                alt="cars"
              />
            </div>
          </div>
        </div>
      </merg>
      <Footer />
    </>
  );
}
