import axios from "axios";

export const getCiaVehicles = async (vehicleJsonSearchRequestData, headers) => {
  try {
    const response = await axios.post(
      "https://backend.carsinafrica.com/frontend/getCarListing",
      vehicleJsonSearchRequestData,
      {
        headers: headers,
      }
    );
    if (response.data.status === "failed") {
      return { status: "failed", data: [], vehicle_source: "cars-in-africa" };
    } else {
      return {
        status: "success",
        data: response.data.data,
        vehicle_source: "cars-in-africa",
      };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "failed", data: [], vehicle_source: "cars-in-africa" };
  }
};

export const getSavaariVehicles = async (
  vehicleJsonSearchRequestData,
  headers
) => {
  try {
    const response = await axios.get(
      `https://backend.carsinafrica.com/savaari_api/get_vehicle_list.php?service=${vehicleJsonSearchRequestData.service}&sub_service=${vehicleJsonSearchRequestData.sub_service}&pickup_address=${vehicleJsonSearchRequestData.pickup_address}&pickup_city=${vehicleJsonSearchRequestData.pickup_city}&pickup_state=${vehicleJsonSearchRequestData.pickup_state}&pickup_latitude=${vehicleJsonSearchRequestData.pickup_latitude}&pickup_longitude=${vehicleJsonSearchRequestData.pickup_longitude}&pickup_date=${vehicleJsonSearchRequestData.savaariPickupDate}&pickup_time=${vehicleJsonSearchRequestData.savaariPickupTime}&drop_address=${vehicleJsonSearchRequestData.dropoff_address}&drop_city=${vehicleJsonSearchRequestData.dropoff_city}&drop_state=${vehicleJsonSearchRequestData.dropoff_state}&drop_latitude=${vehicleJsonSearchRequestData.dropoff_latitude}&drop_longitude=${vehicleJsonSearchRequestData.dropoff_longitude}&drop_date=${vehicleJsonSearchRequestData.savaariDropOffDate}&drop_time=${vehicleJsonSearchRequestData.savaariDropOffTime}`
    );
    if (response.data.status === "failed") {
      return { status: "failed", data: [], vehicle_source: "savaari" };
    } else {
      return {
        status: "success",
        data: response.data.data,
        vehicle_source: "savaari",
      };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "failed", data: [], vehicle_source: "savaari" };
  }
};

export const getEuropeCarsVehicles = async (
  vehicleJsonSearchRequestData,
  headers
) => {
  try {
    const response = await axios.get(
      `https://backend.carsinafrica.com/europe_cars_apis/get_multiple_rates.php?pickup_address=${vehicleJsonSearchRequestData.pickup_address}&pickup_city=${vehicleJsonSearchRequestData.pickup_city}&pickup_state=${vehicleJsonSearchRequestData.pickup_state}&pickup_country=${vehicleJsonSearchRequestData.pickup_country}&pickup_countryiso=${vehicleJsonSearchRequestData.pickup_country_iso}&pickup_date=${vehicleJsonSearchRequestData.pick_date}&pickup_time=${vehicleJsonSearchRequestData.pick_time}&drop_address=${vehicleJsonSearchRequestData.dropoff_address}&drop_city=${vehicleJsonSearchRequestData.dropoff_city}&drop_state=${vehicleJsonSearchRequestData.dropoff_state}&drop_country=${vehicleJsonSearchRequestData.dropoff_country}&drop_countryiso=${vehicleJsonSearchRequestData.dropoff_country_iso}&drop_date=${vehicleJsonSearchRequestData.drop_date}&drop_time=${vehicleJsonSearchRequestData.drop_time}&age=${vehicleJsonSearchRequestData.user_age}`
    );
    if (response.data.status === "failed") {
      return { status: "failed", data: [], vehicle_source: "europe-cars" };
    } else {
      return {
        status: "success",
        data: response.data.data,
        vehicle_source: "europe-cars",
      };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "failed", data: [], vehicle_source: "europe-cars" };
  }
};

export const getTalixoVehicles = async (
  vehicleJsonSearchRequestData,
  headers
) => {
  try {
    const response = await axios.get(
      `https://backend.carsinafrica.com/talixo_api/get_vehicle_list.php?pickup_lat=${vehicleJsonSearchRequestData.pickup_latitude}&pickup_lng=${vehicleJsonSearchRequestData.pickup_longitude}&drop_lat=${vehicleJsonSearchRequestData.dropoff_latitude}&drop_lng=${vehicleJsonSearchRequestData.dropoff_longitude}&pickup_date=${vehicleJsonSearchRequestData.pick_date}&pickup_time=${vehicleJsonSearchRequestData.pick_time}&drop_date=${vehicleJsonSearchRequestData.drop_date}&drop_time=${vehicleJsonSearchRequestData.drop_time}&passengers=1&sport_luggage=0&animals=0&luggage=1`
    );
    if (response.data.status === "failed") {
      return { status: "failed", data: [], vehicle_source: "talixo" };
    } else {
      return {
        status: "success",
        data: response.data.data,
        vehicle_source: "talixo",
      };
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return { status: "failed", data: [], vehicle_source: "talixo" };
  }
};

export const getCarListingData = async (
  service,
  subService,
  tripType,
  pAddress,
  pCity,
  pState,
  pLatitude,
  pLongitude,
  pDate,
  pTime,
  dAddress,
  dCity,
  dState,
  dLatitude,
  dLongitude,
  dDate,
  dTime,
  country,
  countryIso,
  userId,
  userAge
) => {
  // console.log(
  //   "Service:", service,
  //   "Sub Service:", subService,
  //   "Trip Type:", tripType,
  //   "Pickup Address:", pAddress,
  //   "Pickup City:", pCity,
  //   "Pickup State:", pState,
  //   "Pickup Latitude:", pLatitude,
  //   "Pickup Longitude:", pLongitude,
  //   "Pickup Date:", pDate,
  //   "Pickup Time:", pTime,
  //   "Dropoff Address:", dAddress,
  //   "Dropoff City:", dCity,
  //   "Dropoff State:", dState,
  //   "Dropoff Latitude:", dLatitude,
  //   "Dropoff Longitude:", dLongitude,
  //   "Dropoff Date:", dDate,
  //   "Dropoff Time:", dTime,
  //   "Country:", country,
  //   "Country ISO:", countryIso,
  //   "User ID:", userId,
  //   "User Age:", userAge
  // );

  const headers = {
    "Content-Type": "application/json",
  };

  var pickupDateParts = pDate.split("-");
  var pickupYear = pickupDateParts[2];
  var pickupMonth = pickupDateParts[1];
  var pickupDay = pickupDateParts[0];

  var pickupTimeParts = pTime.split(":");
  var pickupHour = pickupTimeParts[0];
  var pickupMinute = pickupTimeParts[1];

  var dropoffDateParts = dDate.split("-");
  var dropoffYear = dropoffDateParts[2];
  var dropoffMonth = dropoffDateParts[1];
  var dropoffDay = dropoffDateParts[0];

  var dropoffTimeParts = dTime.split(":");
  var dropoffHour = dropoffTimeParts[0];
  var dropoffMinute = dropoffTimeParts[1];

  const savaariPickupDate = `${pDate}`;
  const savaariPickupTime = `${pTime}`;
  const savaariDropOffDate = `${dDate}`;
  const savaariDropOffTime = `${dTime}`;

  const europeCarsPickupDate = `${pickupYear}${pickupMonth}${pickupDay}`;
  const europeCarsPickupTime = `${pickupHour}${pickupMinute}`;
  const europeCarsDropOffDate = `${dropoffYear}${dropoffMonth}${dropoffDay}`;
  const europeCarsDropOffTime = `${dropoffHour}${dropoffMinute}`;

  const talixoPickupDate = `${pickupYear}-${pickupMonth}-${pickupDay}`;
  const talixoPickupTime = `${pickupHour}:${pickupMinute}`;
  const talixoDropOffDate = `${dropoffYear}-${dropoffMonth}-${dropoffDay}`;
  const talixoDropOffTime = `${dropoffHour}:${dropoffMinute}`;

  const vehicleSearchRequestData = {
    service: service,
    sub_service: subService,
    trip_type: tripType,
    pickup_address: pAddress,
    pickup_city: pCity,
    pickup_state: pState,
    pickup_latitude: pLatitude,
    pickup_longitude: pLongitude,
    pickup_country: country,
    pickup_country_iso: countryIso,
    dropoff_address: dAddress,
    dropoff_city: dCity,
    dropoff_state: dState,
    dropoff_latitude: dLatitude,
    dropoff_longitude: dLongitude,
    dropoff_country: country,
    dropoff_country_iso: countryIso,
    pick_date: pDate,
    pick_time: pTime,
    drop_date: dDate,
    drop_time: dTime,
    country: country,
    user_id: userId,
    user_age: userAge,
    savaariPickupDate: savaariPickupDate,
    savaariPickupTime: savaariPickupTime,
    savaariDropOffDate: savaariDropOffDate,
    savaariDropOffTime: savaariDropOffTime,
    europeCarsPickupDate: europeCarsPickupDate,
    europeCarsPickupTime: europeCarsPickupTime,
    europeCarsDropOffDate: europeCarsDropOffDate,
    europeCarsDropOffTime: europeCarsDropOffTime,
    talixoPickupDate: talixoPickupDate,
    talixoPickupTime: talixoPickupTime,
    talixoDropOffDate: talixoDropOffDate,
    talixoDropOffTime: talixoDropOffTime,
  };

  try {

    const ciaVehicleResponse = await getCiaVehicles(
      vehicleSearchRequestData,
      headers
    );

    if (ciaVehicleResponse.status === "success") {
      return ciaVehicleResponse;
    } else if (countryIso === "IN" && service !== "selfdrive") {
      const savaariVehicleResponse = await getSavaariVehicles(
        vehicleSearchRequestData,
        headers
      );
      if (savaariVehicleResponse.status === "success") {
        return savaariVehicleResponse;
      }
    } else if (service === "selfdrive") {
      const europeCarsVehicleResponse = await getEuropeCarsVehicles(
        vehicleSearchRequestData,
        headers
      );
      if (europeCarsVehicleResponse.status === "success") {
        return europeCarsVehicleResponse;
      }
    } else if (service === "airport-transfer") {
      const talixoVehicleResponse = await getTalixoVehicles(
        vehicleSearchRequestData,
        headers
      );
      if (talixoVehicleResponse.status === "success") {
        return talixoVehicleResponse;
      }
    }

    // If none of the APIs return success, return failure status
    return {
      status: "failed",
      data: [],
      message: "none of the APIs return success",
    };
  } catch (error) {
    console.error("An error occurred while fetching vehicle data:", error);
    return { success: false, data: null };
  }
};
