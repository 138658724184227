import React from "react";
import LeaseChangePasswordComp from "../../components/ChangePassword/LeaseChangePasswordComp";
import Footer from "../../components/Footer/Footer";
import LeaseHeader from "../../components/Header/LeaseHeader";
export default function LeaseChangePassword() {
  return (
    <>
      <LeaseHeader />
      <LeaseChangePasswordComp />
      <Footer />
    </>
  );
}
