import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { Helmet } from "react-helmet";
export default function TermsandConditions() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("terms-and-conditions");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/privacy_policy.jpg"}
          className="img-fluid"
          alt="privacy policy"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Terms & Conditions</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width full-width pt-3">
        <div className="row">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                Chauffeur Drive
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                Self Drive
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <div className="row" id="car_rent">
                <div className="rn-page-title-inner">
                  <p />
                  <h5 className="text-center">
                    Car Renting Terms and Conditions
                  </h5>
                </div>{" "}
                <p />
                <div className="container full-width full-width">
                  <div className="row" id="car_rent">
                    <table
                      className="table table-bordered"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Cancellation Done</th>
                          <th scope="col">
                            Percentage of deduction on Total Rental amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Prior to 48 Hours</td>
                          <td>No Cancellation Charge</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Within 24-48 hours from rental time</td>
                          <td>No Cancellation Charge</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Within 4 - 24 hours from rental time</td>
                          <td>No Cancellation Charge</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Within 0 - 4 hours from rental time</td>
                          <td>100%</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>No show</td>
                          <td>100%</td>
                        </tr>
                        <tr>
                          <th scope="row">No show</th>
                          <td>
                            In case of No-Show no amount will be refunded to the
                            User -
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Cleanliness</th>
                          <td>
                            Company does not allow the Vehicle to be used to
                            carry pets, goods or any other object which can
                            annihilate the upholstery/any of the vehicles. In
                            such event, the User will be charged on the actual
                            cost towards repairing/cleaning of interior /
                            exterior of vehicle.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Replacement Car</th>
                          <td>
                            Replacement Vehicle shall be provided within a city
                            limits only (Subject to availability of similar
                            segment in the city) in all such cases where in a
                            Vehicle develops mechanical error and restrain the
                            User from its use. In a case, where in Vehicle
                            develops such error outside the city limit,
                            replacement Vehicle will not be provided and amount
                            equivalent to agreed rental will be charged. Company
                            decision related to replacement vehicle will be
                            final.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">No show</th>
                          <td>
                            In case of No-Show no amount will be refunded to the
                            User -
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Cleanliness</th>
                          <td>
                            Company does not allow the Vehicle to be used to
                            carry pets, goods or any other object which can
                            annihilate the upholstery/any of the vehicles. In
                            such event, the User will be charged on the actual
                            cost towards repairing/cleaning of interior /
                            exterior of vehicle.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Replacement Car</th>
                          <td>
                            Replacement Vehicle shall be provided within a city
                            limits only (Subject to availability of similar
                            segment in the city) in all such cases where in a
                            Vehicle develops mechanical error and restrain the
                            User from its use. In a case, where in Vehicle
                            develops such error outside the city limit,
                            replacement Vehicle will not be provided and amount
                            equivalent to agreed rental will be charged. Company
                            decision related to replacement vehicle will be
                            final.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Fuel</th>
                          <td>
                            For Chauffeur drive cars, fuel is inclusive of
                            selected mileage. Any excess to the selected mileage
                            shall be charged separately.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Cancellation</th>
                          <td>
                            Reservation need to be cancelled through Website
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Extra Time</th>
                          <td>
                            Extra Hours will be applicable after 30 minutes over
                            the basic package selected by the renter.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Parking &amp; Tolls</th>
                          <td>
                            Parking and toll charges to be borne by the
                            customer. On Extra Usage the cost of Extra hour
                            and/or Extra KMs will be charged.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row">Extra Usage Policy</th>
                          <td>CHAUFFEUR DRIVE RENTALS:</td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Airport &amp; Railway Transfer&nbsp;: If the
                            transfer time exceeds 4 hours, an extension maximum
                            Upto 1 hour is available with guest with no extra
                            charge. Post extension INR 200/hour will be charged
                            for any extra usage.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Outstation Travel&nbsp;: Outstation Travel does not
                            include one way rentals and Kilometers will be
                            calculated from the city of origin.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            If the travel exceeds 250 kms in a day then extra
                            kilometers will be charged depending on the car
                            model.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            If the travel exceeds beyond 24hrs then and
                            extension of 1 hour is available to the guest with
                            no extra charge. Post extension 1 day rental will be
                            charged on daily basis depending on the car model.
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Local/Within City Travel :&nbsp;If the travel
                            exceeds beyond the booked package the customer will
                            be billed for extra usage. Extra usage will be
                            billed on extra hour rate of the booked car
                            category. Every extra hour has 10KMs. Any usage
                            beyond the packaged KM in the hour will be billed at
                            extra KM rate. Illustration:
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Guest books a ride for 5hours/50kms (refer below
                            example)
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>Car Booked: Honda City</td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>Extra Hour rate (includes10 KMs): Rs 586</td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>Extra KM rate: Rs 32</td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Case 1 : Usage Exceeds 5 Hrs: Actual Usage: 7Hr
                            &amp; 65KMs Extra Usage: 2 Extra Hrs. and no extra
                            KMs Extra Payable: 586*2 = Rs 1172
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th scope="row" />
                          <td>
                            Case 2 : Usage exceeds 5hrs/50 kms: Actual Usage:
                            7Hr &amp; 85KMs Extra Usage: (2 Extra Hrs) and (15
                            extra KMs) Extra Payable: (586*2) + (15*32) =Rs 1652
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                    <div className="container full-width">
                      <div className="row">
                        <table
                          className="table table-bordered"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">CAR TYPE</th>
                              <th scope="col">1HR / 10 KM</th>
                              <th scope="col">Extra KM</th>
                              <th scope="col">Extra KM</th>
                              <th scope="col">Extra HR</th>
                              <th scope="col">Extra KM</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Amaze/Dzire/Etios</th>
                              <td>441</td>
                              <td>24</td>
                              <td>21</td>
                              <td>134</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">Honda City/Nissan Sunny</th>
                              <td>586</td>
                              <td>32</td>
                              <td>27</td>
                              <td>179</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">Innova Crysta</th>
                              <td>779</td>
                              <td>43</td>
                              <td>38</td>
                              <td>255</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">Toyota Corolla/Atis</th>
                              <td>779</td>
                              <td>43</td>
                              <td>38</td>
                              <td>255</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">Toyota Camry/ Honda Accord</th>
                              <td>2154</td>
                              <td>100</td>
                              <td>51</td>
                              <td>350</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">BMW 5 Seris/Merc E Class</th>
                              <td>2148</td>
                              <td>124</td>
                              <td>102</td>
                              <td>5718</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">Merc S Class</th>
                              <td>4701</td>
                              <td>235</td>
                              <td>215</td>
                              <td>10800</td>
                              <td>NA</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                Notes : Above price are not
                                including&nbsp;&nbsp;GST @5%
                              </th>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </table>
                        <table
                          className="table table-bordered"
                          style={{ backgroundColor: "white" }}
                        >
                          <tbody>
                            <tr>
                              <th scope="row">Modify Bookings</th>
                              <td>
                                Revised bookings cannot be cancelled or refunded
                                &amp; an additional payment of INR 1 has to be
                                paid to confirm the changes after which the
                                previous booking will be annulled.
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                If the revised amount is greater than the
                                previous amount, the additional amount has to be
                                paid firsthand &amp; if the amount is lesser,
                                the due amount will be refunded after the
                                booking period.
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                For Self-drive users can modify: Booking Period,
                                End Date &amp; Time, Delivery Address, City, and
                                Car.
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                For Chauffeur drive users can modify: Booking
                                Period, Reporting Address, City, Car, and
                                Service Type (Airport Transfer/Within
                                City/Outstation/One Way Transfer).
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Refund Policy</th>
                              <td>
                                CIA India will process your refund within 21
                                working days as per our cancellation and refund
                                policy.
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2">
                                Note: To cancel a booking, guest needs to Sign
                                in to CIA account either through Website (
                                www.carsinafrica.com ) with their registered ID
                                and password and self-cancel a booking from the
                                "Bookings" tab provided in the Dashboard.
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex={0}
            >
              <div
                className="tab-pane fade active show pt-3"
                id="profile2"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <table
                  className="table table-bordered"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Daily Rental- Terms and Conditions</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Eligibility Age</td>
                      <td>18 years or above</td>
                    </tr>
                    <tr>
                      <td>Documents</td>
                      <td>Indian National : Driving Licence</td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>
                        Latest Electricity Bill. Voter ID card Can be additional
                        and optional
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>Aadhaar Card Number and Copy.</td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>
                        Documents should be email from Corporate email ID.
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>
                        Self Employed : should provide GST or PAN of company.
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>
                        Foreign National: &nbsp; International Driving Permit
                        (IDP).
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>Passport Copy.</td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>Any Resident proof apart from Passport.</td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>Credit Card.</td>
                    </tr>
                    <tr>
                      <th scope="row" />
                      <td>
                        Credit Card with desired limit of Security Deposit of
                        Rs.5,000 (Rupees Five Thousand Only). If the User does
                        not present the above documents in original at the time
                        of obtaining delivery of the Vehicle, the company shall
                        be entitled to refuse the delivery of the Vehicle and
                        cancel this Agreement in such an event Rs.1000 or the
                        rental amount whichever is less shall be charged to the
                        renter.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Note: Individuals holding Non India Driving License need
                        to carry IDP [International Driving Permit] at the time
                        of delivery of car. Carsinafrica will not hand over the
                        car in case the IDP is not shared with us. No refunds
                        will be made in such a case.
                      </td>
                    </tr>
                    <tr>
                      <td>Rental Period</td>
                      <td>
                        The said period shall be calculated from the time the
                        User collects the Vehicle and up to the time the User
                        returns the rental vehicle.
                      </td>
                    </tr>
                    <tr>
                      <td>Other terms</td>
                      <td>As per terms given below</td>
                    </tr>
                    <tr>
                      <td>Delivery / Collection</td>
                      <td>
                        Vehicle must be returned at the same location from where
                        it was delivered to the renter.
                      </td>
                    </tr>
                    <tr>
                      <td>Included KMs</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>Fuel</td>
                      <td>
                        The Vehicle will be provided with a full tank of fuel.
                        In case the tank is not full at the time of return of
                        Vehicle, the User will be charged for short fuel on fuel
                        price of the day the car was returned.
                      </td>
                    </tr>
                    <tr>
                      <td>GPS Navigation system/Child Safety Seat</td>
                      <td>
                        GPS Navigation / Child safety seat shall be available on
                        request with additional charges (Subject to
                        availability)
                      </td>
                    </tr>
                    <tr>
                      <td>Threshold time</td>
                      <td>
                        In case of late return, the User will be allowed a
                        leverage of 1 hour for returning the Vehicle after that
                        late return charges shall be applicable as below.
                      </td>
                    </tr>
                    <tr>
                      <td>Cancellation</td>
                      <td>
                        In case of late return, the User will be allowed a
                        leverage of 1 hour for returning the Vehicle after that
                        late return charges shall be applicable as below.
                      </td>
                    </tr>{" "}
                  </tbody>
                </table>
                <table
                  className="table table-bordered"
                  style={{ backgroundColor: "white" }}
                >
                  <tbody>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Cancellation Done</th>
                      <th scope="col">
                        Percentage of deduction on Total Rental amount
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">1</th>
                      <td>Prior to 48 Hours</td>
                      <td>No Cancellation Charge</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Within 24-48 hours from rental time</td>
                      <td>No Cancellation Charge</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Within 4 - 24 hours from rental time</td>
                      <td>No Cancellation Charge</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Within 0 - 4 hours from rental time</td>
                      <td>100%</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>No show</td>
                      <td>100%</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Notes : Above price are not including&nbsp;&nbsp;GST @5%
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <td>Extra Usage Policy</td>
                      <td>SELF DRIVE RENTALS:</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td />
                      <td>
                        Guest needs to inform Carsinafrica in advance for rental
                        extension. An extension of up to 1 hours is permissible
                        if informed with no extra charges.If the extension
                        exceeds 1 hours, following charges will be applicable
                        for every hour including the extension:
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered"
                  style={{ backgroundColor: "white" }}
                >
                  <tbody>
                    <tr>
                      <th scope="col">Late Fee (Per Hour)</th>
                      <th scope="col">Car Model</th>
                    </tr>
                    <tr>
                      <td>Rs. 300/hr.</td>
                      <td>Swift, Figo, i10 Grand, Dzire and Amaze</td>
                    </tr>
                    <tr>
                      <td>Rs. 400/hr.</td>
                      <td>Ciaz, Etios, Scorpio, and City</td>
                    </tr>
                    <tr>
                      <td>Within 4 - 24 hours from rental time</td>
                      <td>No Cancellation Charge</td>
                    </tr>
                    <tr>
                      <td>Rs. 400/hr.</td>
                      <td>
                        Duster, Baleno, Brezza, Creta, Eco Sports, Ertiga,
                        Innova, Innova Crysta and Xuv500
                      </td>
                    </tr>
                    <tr>
                      <td>Rs. 1000/hr.</td>
                      <td>BMW 5 SERIES</td>
                    </tr>
                    <tr>
                      <td>Rs. 1250/hr.</td>
                      <td>BMW X5</td>
                    </tr>
                    <tr>
                      <td>
                        For extension/late return, guests are requested to check
                        with Carsinafrica Customer Care (+91 124-4724888) or
                        email&nbsp;(support@carsinafrica.com)&nbsp;at least 4
                        hours prior to the drop off time.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <td>Over Speeding Charges</td>
                      <td>
                        Customers are allowed to drive the Vehicle up to a
                        maximum speed of 80 km/hr, beyond which the Vehicle will
                        be considered as over speeding. A penalty of Rs. 200
                        will be charged on the first instance, followed by a
                        further penalty of Rs. 500 and Rs. 1000 for 2nd instance
                        and 3rd instance respectively making it a total of Rs.
                        1,700/- after third instance. After the third instance,
                        Booking will stand cancelled and the Company shall get
                        the right to take back the Vehicle and terminate the
                        Agreement.
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Cancellation Policy (CHAUFFEUR DRIVE &amp; SELF DRIVE
                        RENTALS)
                      </td>
                      <td>
                        If booking is cancelled prior to 48 hours from rental
                        start time, no cancellation charges will be applied on
                        booking amount.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        If booking is cancelled within to 24 - 48 hours from
                        rental start time, 3% processing fee will be charged on
                        booking amount.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        If booking is cancelled within 4 - 24 hours from rental
                        start time, 50% cancelation charge will be applied on
                        the total booking amount.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        If booking is cancelled within 0 - 4 hours from rental
                        start time or “NO SHOW”, 100% cancelation charge will be
                        applied on the total booking amount.
                      </td>
                    </tr>
                    <tr>
                      <td>No show</td>
                      <td>
                        In case of No-Show no amount will be refunded to the
                        User -
                      </td>
                    </tr>
                    <tr>
                      <td>Vehicle Damage</td>
                      <td>
                        Damage charges to be paid by User in terms of the Rental
                        Agreement. In case damage cost goes beyond Security
                        Deposit Amount insurance claim may be filed and
                        Repair/depreciation amount (In case of insurance claim)
                        &amp; Parts which are not covered under insurance will
                        be recovered from the User. The assessment of damage
                        made by Company will be final. Insurance claim is
                        subject to Company"s discretion.
                      </td>
                    </tr>
                    <tr>
                      <td>Outstation</td>
                      <td>
                        When traveling out of state, it is always important to
                        step at RTO check post to pay the government applicable
                        tax. Cost/penalties pertaining to such interstate tax,
                        toll or any other govt. levied tax will be borne by the
                        User directly.
                      </td>
                    </tr>
                    <tr>
                      <td>Traffic Rule Violation</td>
                      <td>
                        Cost for any traffic rule violation during the Rental
                        Period will be borne by the User directly. In cases,
                        where challans is received by the Company via post upon
                        completion of the Agreement, the User authorizes the
                        Company to charge the same on the credit card number of
                        the User as mentioned in this Agreement offline. In the
                        event where the Company has not charged on the credit
                        card number of the User, it shall be entitled to recover
                        the amount from the User in his next booking. The User
                        shall follow all the rules and regulation under
                        applicable laws while driving the Vehicle and otherwise
                        including applicable speed limits.
                      </td>
                    </tr>
                    <tr>
                      <td>Speed Limit</td>
                      <td>
                        Disclaimer: Taking into the account the safety of
                        passengers, Central Government has issued a circular
                        mandating all commercial vehicles to be fitted with a
                        speed governor (speed limiting device) having a maximum
                        speed limit of 80 KM/Hr.
                      </td>
                    </tr>
                    <tr>
                      <td>Cleanliness</td>
                      <td>
                        Company does not allow the Vehicle to be used to carry
                        pets, goods or any other object which can annihilate the
                        upholstery/any of the vehicles. In such event, the User
                        will be charged on the actual cost towards
                        repairing/cleaning of interior / exterior of vehicle.
                      </td>
                    </tr>
                    <tr>
                      <td>Replacement Car</td>
                      <td>
                        Replacement Vehicle shall be provided within a city
                        limits only (Subject to availability of similar segment
                        in the city) in all such cases where in a Vehicle
                        develops mechanical error and restrain the User from its
                        use. In a case, where in Vehicle develops such error
                        outside the city limit, replacement Vehicle will not be
                        provided and amount equivalent to agreed rental will be
                        charged. Company decision related to replacement vehicle
                        will be final.
                      </td>
                    </tr>
                    <tr>
                      <td>CAR UPGRADE POLICY</td>
                      <td>
                        In case the selected car model is unavailable,
                        Carsinafrica will try it's best to provide an equivalent
                        car model or upgrade the car with no additional charge
                        to the customer. Customer, in this case has the right to
                        accept or reject the new allotted car.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        a. In case customer does not want the upgraded car,
                        customer has the right to cancel his/her reservation. In
                        such cases, 0% cancellation charge will be applicable.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        b. Note:&nbsp;Mileage is independent to car model. Cases
                        where the car is upgraded to the customer, Carsinafrica
                        will not be liable to reimburse the difference in the
                        mileage amount to the guest.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        In case the selected car model is unavailable and a
                        lower segment car is allotted to the customer. The
                        difference in the final base amount will be reimbursed
                        to the customer by Carsinafrica.
                      </td>
                    </tr>
                    <tr>
                      <td>Modify Bookings</td>
                      <td>
                        Revised bookings cannot be cancelled or refunded &amp;
                        an additional payment of INR 1 has to be paid to confirm
                        the changes after which the previous booking will be
                        annulled.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        If the revised amount is greater than the previous
                        amount, the additional amount has to be paid firsthand
                        &amp; if the amount is lesser, the due amount will be
                        refunded after the booking period.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        For Self-drive can modify: Booking Period, End Date
                        &amp; Time, Delivery Address, City and Car.
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        For Chauffeur drive user can modify: Booking Period,
                        Reporting Address, City, Car and Service Type (Airport
                        Transfer/Within City/Outstation/One Way Transfer).
                      </td>
                    </tr>
                    <tr>
                      <td>FASTag</td>
                      <td>
                        All our Self Drive cars are equipped with FASTag as per
                        Government guidelines. All toll charges deducted through
                        FASTag for your rental will be deducted from the
                        security amount.Any toll falls between Carsinafrica hub
                        and Guest pickup/drop location shall be billed and
                        deducted from Guest security amount
                      </td>
                    </tr>
                    <tr>
                      <td>Refund Policy</td>
                      <td>
                        Carsinafrica India will process your refund within 21
                        working days as per our cancellation and refund policy.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
