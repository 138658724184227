/*
@copyright

Cars In Africa v0.5

Copyright (C) 2023 Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/> All rights reserved.
Design and developed by Satguru Travel And Tourism Pvt Ltd <https://satgurutravel.com/>

 */

// START - Local Storage

const storeToken = (value) => {
  localStorage.setItem("api_token", value.api_token);
  localStorage.setItem("user_id", value.user_id);
  localStorage.setItem("user_name", value.user_name);
  localStorage.setItem("emailid", value.emailid);
  localStorage.setItem("gender", value.gender);
  localStorage.setItem("mobno", value.mobno);
};
const getToken = () => {
  try {
    let userData = {
      api_token: localStorage.getItem("api_token"),
      user_id: localStorage.getItem("user_id"),
      user_name: localStorage.getItem("user_name"),
      emailid: localStorage.getItem("emailid"),
      gender: localStorage.getItem("gender"),
      mobno: localStorage.getItem("mobno"),
    };
    return userData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const removeToken = (userData) => {
  Object.keys(userData).forEach((key) => {
    localStorage.removeItem(key);
  });
};

const storeLeaseUserToken = (value) => {
  localStorage.setItem("lease_api_token", value.lease_api_token);
  localStorage.setItem("lease_user_id", value.lease_user_id);
  localStorage.setItem("lease_user_name", value.lease_user_name);
  localStorage.setItem("lease_emailid", value.lease_emailid);
  localStorage.setItem("lease_gender", value.lease_gender);
  localStorage.setItem("lease_mobno", value.lease_mobno);
};

const getLeaseUserToken = () => {
  try {
    let leaseUserData = {
      lease_api_token: localStorage.getItem("lease_api_token"),
      lease_user_id: localStorage.getItem("lease_user_id"),
      lease_user_name: localStorage.getItem("lease_user_name"),
      lease_emailid: localStorage.getItem("lease_emailid"),
      lease_gender: localStorage.getItem("lease_gender"),
      lease_mobno: localStorage.getItem("lease_mobno"),
    };
    return leaseUserData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const removeLeaseUserToken = (leaseUserData) => {
  Object.keys(leaseUserData).forEach((key) => {
    localStorage.removeItem(key);
  });
};

const storeBookingHistory = (value) => {
  localStorage.setItem("date", value.api_token);
  localStorage.setItem("from", value.user_id);
  localStorage.setItem("to", value.user_name);
  localStorage.setItem("payment", value.emailid);
  localStorage.setItem("status", value.gender);
};

const getBookingHistory = () => {
  try {
    let userData = {
      date: localStorage.getItem("date"),
      from: localStorage.getItem("from"),
      to: localStorage.getItem("to"),
      payment: localStorage.getItem("payment"),
      status: localStorage.getItem("status"),
    };
    return userData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const storeBooking = (success, message) => {
  localStorage.setItem("success", success);
  localStorage.setItem("message", message);
};

const getBooking = () => {
  try {
    let bookingData = {
      success: localStorage.getItem("success"),
      message: localStorage.getItem("message"),
    };
    return bookingData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const storeLocation = (country, state, city, address) => {
  localStorage.setItem("country", country);
  localStorage.setItem("state", state);
  localStorage.setItem("city", city);
  localStorage.setItem("address", address);
};

const getLocation = () => {
  try {
    let locationData = {
      country: localStorage.getItem("country"),
      state: localStorage.getItem("state"),
      city: localStorage.getItem("city"),
    };
    return locationData;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export {
  storeToken,
  getToken,
  removeToken,
  storeLeaseUserToken,
  getLeaseUserToken,
  removeLeaseUserToken,
  storeBookingHistory,
  getBookingHistory,
  storeBooking,
  getBooking,
  storeLocation,
  getLocation,
};

// END - Local Storage
