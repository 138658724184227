import React, { useState } from "react";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { getLeaseUserToken } from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";
import Swal from "sweetalert2";
import axios from "axios";
import "./ChangePassword.css";
export default function ChangePasswordComp() {
  const userLoggedData = getLeaseUserToken();
  const [userCredits, setUserCredits] = useState({
    user_id: userLoggedData.lease_user_id,
  });
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserCredits((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (userCredits.new_password !== userCredits.confirm_new_password) {
      setPasswordError("Passwords do not match.");
      Swal.fire({
        title: "Error",
        text: "New Password & Confirm Password Does Not Match",
        icon: "error",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://backend.carsinafrica.com/frontend/changePassword",
        userCredits
      );

      if (response.data.status === "Failed") {
        Swal.fire({
          title: "Error",
          // text: response.data.message,
          icon: "error",
        }).then(() => {
          setIsLoading(false);
        });
      } else {
        Swal.fire({
          title: "Success",
          text: response.data.message,
          icon: "success",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        }).then(() => {
          setIsLoading(false);
          window.location.href = `/lease`;
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while processing your request",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <>
      <div className="container pt-5 mt-4 mb-5">
        <div className="custom-container-gif">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/loader.gif"}
              className="img-fluid rounded"
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="registrar registrar-container">
        <div className="container">
          <div className="row enquiry-section">
            <div className="col-lg-6">
              <h3 className="display-5 mb-4">Change Password</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <Input
                    placeholder="Enter your Username"
                    name="username"
                    size="large"
                    required
                    autoComplete="username"
                    value={userLoggedData.lease_user_name}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="old_password" className="form-label">
                    Old Password
                  </label>
                  <div className="input-group">
                    <Input.Password
                      placeholder="Enter Your Old Password"
                      name="old_password"
                      onChange={handleUserLoginDetails}
                      size="large"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      autoComplete="current-password"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="new_password" className="form-label">
                    New Password
                  </label>
                  <div className="input-group">
                    <Input.Password
                      placeholder="Enter Your New Password"
                      name="new_password"
                      size="large"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      onChange={handleUserLoginDetails}
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="confirm_new_password" className="form-label">
                    Confirm New Password
                  </label>
                  <div className="input-group">
                    <Input.Password
                      placeholder="Confirm Your New Password"
                      name="confirm_new_password"
                      size="large"
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      onChange={handleUserLoginDetails}
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>

                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </form>
            </div>
            <div className="col-lg-6">
              <img
                src={`${process.env.PUBLIC_URL}/images/forget_password.jpg`}
                className="img-responsive"
                alt="Change Password"
                id="enquiry-image"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
