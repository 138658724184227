import React from "react";
import "./lease-offers.css";

const LeaseOffers = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <h2 class="title_lines mb-5">Latest Offers</h2>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease4.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3 pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease3.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease4.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card promo-card">
              <div className="card-body text-left">
                <img
                  src={process.env.PUBLIC_URL + "/images/lease/lease3.jpg"}
                  className="img-fluid"
                  alt="Promo"
                />
                <h5 className="card-title pt-3">
                  FLAT 40% OFF ON SELECT VEHICLES
                </h5>
                <p className="card-text">
                  Book your airport, local and outstation ride at FLAT 40% OFF*
                  from May 1st, 2024
                </p>
                {/* <div className="promo-code btn btn-primary">GRAB NOW</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaseOffers;
