import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LoginComp from "../../components/LoginRegister/LoginComp";
export default function UserLogin() {
  return (
    <>
      <Header />
      <LoginComp />
      <Footer />
    </>
  );
}
