import React from "react";
import LeaseHeader from "../../components/Header/LeaseHeader";
import LeaseForgotPasswordComp from "../../components/LeaseChangePassword/LeaseForgotPasswordComp";
import Footer from "../../components/Footer/Footer";

const LeaseForgotPassword = () => {
  return (
    <>
      <LeaseHeader />
      <LeaseForgotPasswordComp />
      <Footer />
    </>
  );
};

export default LeaseForgotPassword;
