import React from "react";
import CountryDetail from "../../components/CountryDetails/CountryDetails";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
export default function CountryLocation() {
  return (
    <>
      <Header />
      <CountryDetail />
      <Footer />
    </>
  )
}