import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Lease-header.css";
import {
  getLeaseUserToken,
  removeLeaseUserToken,
} from "../../services/Apis/CarsInAfrica/Auth/LocalStorageService";

export default function LeaseHeader() {
  const location = useLocation();
  const activeLinkLocation = useLocation().pathname;
  const userLoggedData = getLeaseUserToken();
  const countryUrl = process.env.REACT_APP_URL_COUNTRY_CODE;
  const scrollRef = useRef(null);
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  // const googleTranslateElementInit = () => {
  //   if (!window.google || !window.google.translate) return; // Check if Google Translate is already initialized
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   const addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, [location.pathname]);

  const scrollToContent = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleNavbar = () => {
    setNavbarCollapsed(!isNavbarCollapsed);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handleLogout = async () => {
    removeLeaseUserToken(userLoggedData);
    window.location.href = `/lease-user-login`;
  };

  return (
    <>
      <div className="mt-5">
        <header className="lease-header">
          <div className="logo-container">
            <a href="/" className="lease-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/cia_logo.png"}
                alt="logo"
                className="cia-lease-logo"
              />
            </a>
          </div>
          <div className="contact-info">
            {/* <div id="google_translate_element"></div> */}
            {userLoggedData.lease_user_id == null ? (
              <ul className="navbar-nav" id="cia-nav-item">
                <div className="d-flex mob-d-flex">
                  <li className="nav-item">
                    <Link
                      to="/lease-user-login"
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      aria-controls="collapsibleNavbar"
                      aria-expanded={!isNavbarCollapsed}
                    >
                      <button
                        type="button"
                        className="btn btn-danger main-menu-bg-button"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={process.env.PUBLIC_URL + "/images/vector.svg"}
                            alt="Icon"
                            className="mr-2 main-menu-login"
                          />
                          <span id="cia-login-header">Login</span>
                        </div>
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item" id="button-spacing">
                    <Link
                      to="/lease-user-registration"
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#collapsibleNavbar"
                      aria-controls="collapsibleNavbar"
                      aria-expanded={!isNavbarCollapsed}
                    >
                      <button
                        type="button"
                        className="btn btn-danger main-menu-registration-bg"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={process.env.PUBLIC_URL + "/images/vector.svg"}
                            alt="Icon"
                            className="mr-2 btn-main-menu-registration"
                          />
                          <span id="cia-register-header">Register</span>
                        </div>
                      </button>
                    </Link>
                  </li>
                </div>
              </ul>
            ) : (
              <div
                className="navbar-nav main-menu-dropdown-margin"
                id="nav-dropDown"
              >
                <a
                  className="d-profile text-decoration-none dropdown-toggle text-black"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="bi bi-person-circle text-black"
                    id="profile_circle"
                  />{" "}
                  Profile
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-start text-small dashboard-auto-margin"
                  aria-labelledby="dropdownUser1"
                >
                  {/* <li>
                <Link className="dropdown-item" to="/dashboard">
                  <i className="fas fa-chart-bar me-2"></i> Dashboard
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/profile">
                  <i className="fas fa-user me-2"></i> My Profile
                </Link>
              </li> */}
                  <li>
                    <Link className="dropdown-item" to="/lease-change-password">
                      <i className="fas fa-lock me-2"></i> Change Password
                    </Link>
                  </li>
                  {/* <li> */}
                  <div className="dropdown-divider"></div>
                  {/* </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      <i className="fas fa-sign-out-alt me-2"></i> Sign out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </header>
      </div>
    </>
  );
}
