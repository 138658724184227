import React from "react";
import { Link } from "react-router-dom";
import "./Logo.css";
export default function Logo() {
  return (
    <>
      <Link className="navbar-brand" to="/">
        <img
          src={process.env.PUBLIC_URL + "/images/cia_logo.png"}
          id="cia-logo"
          alt="logo"
        />
      </Link>
    </>
  );
}
