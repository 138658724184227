import React from "react";
import LeaseLoginComp from "../../components/LoginRegister/LeaseLoginComp";
import Footer from "../../components/Footer/Footer";
import LeaseHeader from "../../components/Header/LeaseHeader";

export default function LeaseLogin() {
  return (
    <>
      <LeaseHeader />
      <LeaseLoginComp />
      <Footer />
    </>
  );
}
