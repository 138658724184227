import React from "react";
import LeaseHeader from "../../components/Header/LeaseHeader";
import LeaseSearchConsole from "../../components/Lease/LeaseSearchConsole";
import LeaseOffers from "../../components/Lease/LeaseOffers";
import Footer from "../../components/Footer/Footer";

const Lease = () => {
  return (
    <>
      <LeaseHeader />
      <LeaseSearchConsole />
      <LeaseOffers />
      <Footer />
    </>
  );
};

export default Lease;
