import axios from "axios";

export const getMetaData = async (pageName = "home") => {
  try {
    const res = await axios.get(
      `https://backend.carsinafrica.com/frontend/getMetaTagsData?page=${pageName}&country=worldwide&country_iso=WW&url=carsinafrica.com`
    );
    const metadata = res.data.data[0];
    if (res.data.status === "failed") {
      throw new Error("Meta Data Not Found for this page");
    } else {
      return metadata;
    }
  } catch (error) {
    const res = {
      title:
        "Local & Intercity Car Rentals: Airport Transfer, Chauffeur Service, Self-Drive",
      description:
        "Explore top-quality local and intercity car rentals in Africa. Convenient airport transfer, chauffeur service, and self-drive options are available. Book now | CARS IN AFRICA",
      keywords:
        "Local,intercity,Transfer,self-drive,airport transfer,Cars in Africa,Chauffer drive",
      ogLocale: "en_GB",
      ogTitle:
        "Local & Intercity Car Rentals: Airport Transfer, Chauffeur Service, Self-Drive",
      ogDescription:
        "Explore top-quality local and intercity car rentals in Africa. Convenient airport transfer, chauffeur service, and self-drive options are available. Book now | CARS IN AFRICA",
      ogUrl: "https://carsinafrica.com/assets/images/cia_logo.png",
      country: "worldwide",
      country_iso: "ww",
      url: "",
    };
    return res;
  }
};
