import React from "react";
import TrackYourBooking from "../../components/BookingsTracker/TrackYourBooking";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function TrackBooking() {
  return (
    <>
    <Header />
      <TrackYourBooking />
      <Footer />
    </>
  );
}
