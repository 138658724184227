import React from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Form, DatePicker, Input, Checkbox, InputNumber } from "antd";
import LeaseHeader from "../../components/Header/LeaseHeader";
import Footer from "../../components/Footer/Footer";
import LeaseBookingComp from "../../components/Lease/LeaseBookingComp";

const LeaseBooking = () => {
  const {
    term,
    pAddress,
    pCity,
    pState,
    pCountry,
    pCountryIso,
    pLatitude,
    pLongitude,
    vehicleAgeType,
    vehicleTypeId,
    vehicleTypeName,
    userId,
    months,
    years,
  } = useParams();

  // const data = {
  //   term: "short",
  //   vehicleTypeId: "26",
  //   pAddress: "Shanghai, China",
  //   pCity: "Shanghai",
  //   pState: "",
  //   pCountry: "China",
  //   pCountryIso: "CN",
  //   pLatitude: "0",
  //   pLongitude: "0",
  //   userId: "14",
  //   months: 6,
  //   years: 2,
  //   vehicleAgeType: "new",
  // };
  const data = {
    term,
    pAddress,
    pCity,
    pState,
    pCountry,
    pCountryIso,
    pLatitude,
    pLongitude,
    vehicleAgeType,
    vehicleTypeId,
    vehicleTypeName,
    userId,
    months,
    years,
  };

  return (
    <>
      <LeaseHeader />
      <LeaseBookingComp data={data} />
      <Footer />
    </>
  );
};

export default LeaseBooking;
