import React, { useState, useEffect } from "react";
// import "./countryfaqs.css";
import axios from "axios";
import Swal from "sweetalert2";
import './CountryDetails.css';
// import "react-phone-number-input/style.css";
// import './Button/Button.js'
import PhoneInput from "react-phone-number-input";
import { useParams, Link, useNavigate } from "react-router-dom";
export default function CountryDetails() {
  const [contactDetails, setContactDetails] = useState();
  const [contactDetailsFlag, setContactDetailsFlag] = useState(false);
  const [contactDetailsLoading, setContactDetailsLoading] = useState(true);
  const [firstContactDetailFlag, setFirstContactDetailFlag] = useState(true);

  const { countryIso, countryName } = useParams();

  // START - Sending Inquiry To Online DB (inquiry API)
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState();
  // END - Sending Inquiry To Online DB (inquiry API)
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{15}$/;

    if (name.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Name Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (!emailRegex.test(email)) {
      Swal.fire({
        title: "Error",
        text: "Invalid Email Format",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (email.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Email Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (value.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Contact Number Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else if (message.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Message Has Left Blank",
        icon: "error",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else {
      const url = "https://backend.carsinafrica.com/frontend/inquiry";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      // fData.append("contact_number", "+" + countryCode + "-" + contact_number);
      fData.append("contact_number", value);
      fData.append("message", message);

      axios
        .post(url, fData)
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Thank You For Contacting Us, Our Team Will Connect Your Shortly!",
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            window.location.href = "/";
          });
        })
        .catch((error) => alert(error));
    }
  };
  // START -> useEffect when Page Is Reloaded
  useEffect(() => {
    getContactDetails();
  }, []);
  // END -> useEffect when Page Is Reloaded

  function getContactDetails() {
    try {
      axios
        .get(
          `https://backend.carsinafrica.com/onlineApi/country_branch_details.php?country=${countryName}&country-iso=${countryIso}`
        )
        .then(function (response) {
          if (response.data.status === "success") {
            setContactDetails(response.data.data);
            setContactDetailsFlag(true);
            setContactDetailsLoading(false);
          } else {
            setContactDetailsFlag(false);
            setContactDetailsLoading(false);
            // Optionally, handle the error or show a message
            console.error(
              "Failed to fetch contact details:",
              response.data.message
            );
          }
        })
        .catch(function (error) {
          // Handle network or other asynchronous errors here
          console.error("Error fetching contact details:", error);
          setContactDetailsFlag(false);
          setContactDetailsLoading(false);
        });
    } catch (error) {
      // Handle any synchronous errors here
      console.error("Synchronous error in getContactDetails:", error);
      setContactDetailsFlag(false);
      setContactDetailsLoading(false);
    }
  }

  const handleBlur = () => {
    // Check if the entered value exceeds the maximum length of 15 characters
    if (value && value.length > 15) {
      // Truncate the value to the first 15 characters
      const truncatedValue = value.substring(0, 15);
      // Update the state with the truncated value
      setValue(truncatedValue);
    }
  };

  const buttons = document.querySelectorAll(".faq-title");

  function Onclick() {
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const parentCard = button.closest(".card");
        const collapseElement = parentCard.querySelector(".collapse");
        collapseElement.classList.toggle("show");
      });
    });
  }

  const [expandedItem, setExpandedItem] = useState(null);

  const handleAccordionItemClick = (itemId) => {
    setFirstContactDetailFlag(false);
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
    }
  };

  const isItemExpanded = (itemId) => expandedItem === itemId;

  if (contactDetailsFlag === true && contactDetailsLoading === false) {
    return (
      <>
        <section className="banner-section">
          <img
            src="/images/map_location.jpg"
            className="img-fluid"
            alt="Map Travel Adventure"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">
                  {countryName.toUpperCase()}
                </h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <section className="faq-section custom-faq-section">
                {contactDetails.map((contactDetails, key) => (
                  <div className="container" key={key}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq" id="accordion">
                          <div className="card">
                            <div className="card-header" id="faqHeading-1">
                              <div className="mb-0">
                                <h5
                                  className="faq-title"
                                  onClick={() =>
                                    handleAccordionItemClick(
                                      `faqCollapse-${contactDetails.id}`
                                    )
                                  }
                                  style={{ fontWeight: "800" }}
                                >
                                  <i
                                    className={`bi bi-caret-${isItemExpanded(
                                      `faqCollapse-${contactDetails.id}`
                                    )
                                      ? "up"
                                      : "down"
                                      }`}
                                    style={{
                                      float: "right",
                                      fontWeight: "700",
                                    }}
                                  />
                                  <span className="badge">
                                    {contactDetails.id + 1}
                                  </span>
                                  {contactDetails.branch_city.toUpperCase()}
                                </h5>
                              </div>
                            </div>
                            <div
                              id={`faqCollapse-${contactDetails.id}`}
                              className={`collapse ${firstContactDetailFlag &&
                                contactDetails.id === 0
                                ? isItemExpanded(
                                  `faqCollapse-${contactDetails.id}`
                                )
                                  ? ""
                                  : "show"
                                : isItemExpanded(
                                  `faqCollapse-${contactDetails.id}`
                                )
                                  ? "show"
                                  : ""
                                }`}
                              aria-labelledby="faqHeading-1"
                              data-parent="#accordion"
                            >
                              <div className="card-body">
                                <p>
                                  <div className="row mt-3">
                                    <div className="col-lg-4 col-sm-6 pb-3">
                                      <div
                                        className="card h-100 mx-auto"
                                        style={{ border: "1px dashed #6d6d6d" }}
                                      >
                                        <div className="card-body text-center">
                                          <div
                                            className="bg-light rounded-circle p-3"
                                            style={{
                                              width: "fit-content",
                                              margin: "0 auto",
                                            }}
                                          >
                                            <img
                                              className="img-fluid"
                                              src="https://satgurutravel.com/wp-content/uploads/2023/12/location.png"
                                              alt="Location Icon"
                                              style={{ width: "3em" }}
                                            />
                                          </div>
                                          <h5 className="card-title mt-3">
                                            Location
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {contactDetails.address}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 pb-3">
                                      <div
                                        className="card h-100 mx-auto"
                                        style={{ border: "1px dashed #6d6d6d" }}
                                      >
                                        <div className="card-body text-left custom-country-details">
                                          <div
                                            className="bg-light rounded-circle p-3"
                                            style={{
                                              width: "fit-content",
                                              margin: "0 auto",
                                            }}
                                          >
                                            <img
                                              className="img-fluid"
                                              src="https://satgurutravel.com/wp-content/uploads/2023/12/phone.png"
                                              alt="Phone Icon"
                                              style={{ width: "3em" }}
                                            />
                                          </div>
                                          <h5 className="card-title mt-3 text-center">
                                            Phone
                                          </h5>
                                          {/* <a
                                            href={`tel:${contactDetails.contact_number_primary}`}
                                            className="nav-link p-0 text-dark text-center"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            <tel>
                                              {
                                                contactDetails.contact_number_primary
                                              }
                                            </tel>
                                          </a>
                                          <a
                                            href={`tel:${contactDetails.contact_number_secondary}`}
                                            className="nav-link p-0 text-dark text-center"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            <tel>
                                              {
                                                contactDetails.contact_number_secondary
                                              }
                                            </tel>
                                          </a> */}
                                          {contactDetails.contact_number_primary ===
                                            contactDetails.contact_number_secondary ? (
                                            // Rendered when primary and secondary contact numbers are equal
                                            <a
                                              href={`tel:${contactDetails.contact_number_primary}`}
                                              className="nav-link p-0 text-dark text-center"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "700",
                                              }}
                                            >
                                              <tel>
                                                {
                                                  contactDetails.contact_number_primary
                                                }
                                              </tel>
                                            </a>
                                          ) : (
                                            <>
                                              <a
                                                href={`tel:${contactDetails.contact_number_primary}`}
                                                className="nav-link p-0 text-dark text-center"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                <tel>
                                                  {
                                                    contactDetails.contact_number_primary
                                                  }
                                                </tel>
                                              </a>
                                              <a
                                                href={`tel:${contactDetails.contact_number_secondary}`}
                                                className="nav-link p-0 text-dark text-center"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                <tel>
                                                  {
                                                    contactDetails.contact_number_secondary
                                                  }
                                                </tel>
                                              </a>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 pb-3">
                                      <div
                                        className="card h-100 mx-auto"
                                        style={{ border: "1px dashed #6d6d6d" }}
                                      >
                                        <div className="card-body text-left">
                                          <div
                                            className="bg-light rounded-circle p-3"
                                            style={{
                                              width: "fit-content",
                                              margin: "0 auto",
                                            }}
                                          >
                                            <img
                                              className="img-fluid"
                                              src="https://satgurutravel.com/wp-content/uploads/2023/12/address.png"
                                              alt="Email Icon"
                                              style={{ width: "3em" }}
                                            />
                                          </div>
                                          <h5 className="card-title mt-3 text-center">
                                            Email Id
                                          </h5>
                                          {contactDetails.contact_email_primary ===
                                            contactDetails.contact_email_secondary ? (
                                            // Rendered when primary and secondary email addresses are equal
                                            <a
                                              href={`mailto:${contactDetails.contact_email_primary}`}
                                              className="nav-link p-0 text-dark text-center"
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "700",
                                              }}
                                            >
                                              <tel>
                                                {
                                                  contactDetails.contact_email_primary
                                                }
                                              </tel>
                                            </a>
                                          ) : (
                                            // Rendered when primary and secondary email addresses are not equal
                                            <>
                                              <a
                                                href={`mailto:${contactDetails.contact_email_primary}`}
                                                className="nav-link p-0 text-dark text-center"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                <tel>
                                                  {
                                                    contactDetails.contact_email_primary
                                                  }
                                                </tel>
                                              </a>
                                              <a
                                                href={`mailto:${contactDetails.contact_email_secondary}`}
                                                className="nav-link p-0 text-dark text-center"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                <tel>
                                                  {
                                                    contactDetails.contact_email_secondary
                                                  }
                                                </tel>
                                              </a>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </div>

            <div className="col-md-4">
              <div className="container pt-2">
                <div className="row">
                  <div className="col-sm-10 custom-border custom-container">
                    <form onSubmit={handleSubmit}>
                      <h3 className="mb-3">Get an Enquiry</h3>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing.</p> */}
                      <div className="mb-3">
                        {/* <label htmlFor="name" className="form-label">
                          Name
                        </label> */}
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Enter Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="email" className="form-label">
                          Email
                        </label> */}
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Enter Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="contact number" className="form-label">
                          Mobile Number
                        </label> */}
                        <PhoneInput
                          country="IN"
                          defaultCountry="IN"
                          placeholder="Enter Phone Number"
                          value={value}
                          onChange={setValue}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="message" className="form-label">
                          Message
                        </label> */}
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          rows="4"
                          placeholder="Your Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div>
                        <button className="new-button">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="banner-section">
          <img
            src="/images/map_location.jpg"
            className="img-fluid"
            alt="Map Travel Adventure"
          />
          <div className="banner-overlay"></div>
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-md-8">
                <h1 className="banner-caption text-white">
                  {countryName.toUpperCase()}
                </h1>
                <div className="dark-white-line"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <img
                src={process.env.PUBLIC_URL + "/images/loader.gif"}
                className="img-fluid-loading"
              />
            </div>

            <div className="col-md-4">
              <div className="container pt-2">
                <div className="row">
                  <div className="col-sm-10 custom-border custom-container">
                    <form method="POST">
                      <h3 className="mb-3">Get an Enquiry</h3>
                      {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing.</p> */}
                      <div className="mb-3">
                        {/* <label htmlFor="name" className="form-label">
                          Name
                        </label> */}
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Enter Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="email" className="form-label">
                          Email
                        </label> */}
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Enter Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="contact number" className="form-label">
                          Mobile Number
                        </label> */}
                        <PhoneInput
                          country="IN"
                          placeholder="Enter Phone number"
                          value={value}
                          onChange={setValue}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="mb-3">
                        {/* <label htmlFor="message" className="form-label">
                          Message
                        </label> */}
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          rows="4"
                          placeholder="Your Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div>
                        <button className="new-button" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
