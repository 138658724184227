import React, { useLayoutEffect, useState } from "react";
import { DataPolicyBanner } from "../../components/Banner/Banner";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Header/Header";
import StaticContent from "../../components/StaticContent/StaticContent";
import Footer from "../../components/Footer/Footer";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { Helmet } from "react-helmet";

export default function DataPolicy() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("data-policy");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Navbar />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/data_policy.jpg"}
          className="img-fluid"
          alt="data policy"
        />
        <div className="banner-overlay"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Data Privacy Policy</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-2 mt-4 full-width">
        <div className="row">
          <div className="privacybullet">
            <li style={{ textAlign: "justify" }}>
              As a car rental company, we understand the importance of data
              privacy and are committed to protecting the personal information
              of our customers. We take data privacy seriously and have
              implemented various measures to safeguard your information.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              When you rent a car from us, we collect certain personal
              information such as your name, address, phone number, email
              address, driver's license information, and credit card
              information. This information is necessary for us to process your
              rental agreement and to provide you with the services you have
              requested.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              We use this information solely for the purposes of completing your
              rental transaction and to provide customer support in case you
              need assistance during your rental period. We do not share your
              personal information with any third-party companies or
              individuals, except when required by law or in the event of an
              emergency.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              We also use industry-standard encryption technology to protect
              your personal information from unauthorized access or disclosure.
              Our employees are trained to follow strict privacy guidelines and
              are only authorized to access your personal information when
              necessary to perform their job duties.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              We take reasonable measures to protect your personal information
              from unauthorized access, disclosure, or misuse. We use
              industry-standard security measures, including encryption and
              secure servers, to protect your information.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              In addition, we have implemented physical, technical, and
              administrative safeguards to protect your personal information
              from loss, misuse, or alteration. Our systems are regularly
              monitored and audited to ensure that your information is secure.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              If you have any questions or concerns about our data privacy
              policies, please contact us. We are committed to protecting your
              privacy and will do our best to address any concerns you may have.
            </li>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
